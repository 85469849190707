import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query Abuse {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulAbuse {
      ...metaAbuse
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.AbuseQuery>> &
  ILayout = () => {
  return <Template />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<PageProps<Queries.AbuseQuery>> = ({
  data,
  location,
}) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulAbuse?.meta}
      pageTitle="Abuse"
      pathname={location.pathname}
    />
  );
};
